<template>
  <div class="warehouse-page">
    <div class="main-container">
      <div class="aside-wrap">
        <div>供应商</div>
        <el-select
          v-model="orgId"
          placeholder="供应商"
          @change="getCategoryList"
          filterable
          clearable
        >
          <el-option
            v-for="(item, index) in orgList"
            :key="index"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
        <el-form ref="dataForm" inline class="bysearchForm">
          <div>商品分类</div>
          <el-button
            v-if="isAuth('cc:category:save')"
            type="primary"
            @click="$dialog('category', '', false, orgId)"
            >新增分类</el-button
          >
        </el-form>

        <div v-if="isMove">
          请拖动进行排序后
          <div>
            <el-button @click="changeNum" type="success">提交</el-button>
            <el-button @click="cancelMove" type="danger">取消</el-button>
          </div>
        </div>

        <el-table-draggable @input="changeDragg">
          <el-table
            row-key="id"
            height="calc(100vh - 210px)"
            :data="categoryList"
            ref="categoryList"
            border
            :row-class-name="tableRowClassName"
            @selection-change="$selectionChange($event, 'categoryList')"
            @row-click="
              $clickRow(
                $event,
                'categoryList',
                getGoodsList($event, 1),
                colorChange($event),
              )
            "
          >
            <el-table-column
              prop="name"
              header-align="center"
              align="center"
              label="名称"
            >
            </el-table-column>
            <el-table-column
              header-align="center"
              align="center"
              width="120"
              label="是否显示"
              :render-header="renderHeader"
            >
              <template slot-scope="scope">
                <el-switch
                  v-model="scope.row.isShow"
                  @change="changeCategoryShow(scope.row.id, scope.row.isShow)"
                >
                </el-switch>
              </template>
            </el-table-column>
            <el-table-column
              row-key
              header-align="center"
              align="center"
              width="150"
              label="操作"
            >
              <template slot-scope="scope">
                <el-button
                  v-if="isAuth('cc:category:id')"
                  type="text"
                  @click="$dialog('category', scope.row.id, true)"
                  class="el-icon-view"
                ></el-button>
                <el-button
                  v-if="isAuth('cc:category:update')"
                  type="text"
                  @click="$dialog('category', scope.row.id)"
                  class="el-icon-edit"
                ></el-button>
                <el-button
                  v-if="isAuth('cc:category:delete')"
                  type="text"
                  @click="deleteCategory(scope.row.id)"
                  class="el-icon-delete"
                ></el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-table-draggable>
      </div>
      <div class="main-wrap">
        <el-form ref="dataForm" inline class="bysearchForm">
          <div class="searchLine">
            <el-form-item prop="name" class="typeInput">
              <el-input
                v-model="dataForm.name"
                placeholder="商品名称"
              ></el-input>
            </el-form-item>

            <el-form-item prop="sn" class="typeInput">
              <el-input v-model="dataForm.sn" placeholder="商品编号"></el-input>
            </el-form-item>

            <el-form-item prop="isPackage" class="typeInput">
              <el-select
                v-model="dataForm.isPackage"
                placeholder="是否套餐"
                filterable
                clearable
              >
                <el-option
                  v-for="(item, index) in isPackageList"
                  :key="index"
                  :label="item.isPackage"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>

            <el-form-item prop="isUse" class="typeInput">
              <el-select
                v-model="dataForm.isUse"
                placeholder="是否使用"
                filterable
                clearable
              >
                <el-option
                  v-for="(item, index) in isUseList"
                  :key="index"
                  :label="item.isUse"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>

            <el-form-item class="typeInput">
              <el-button @click="getGoodsList(null, 1)">查询</el-button>
              <el-button
                v-if="isAuth('tc:goods:save')"
                type="primary"
                @click="$dialog('goodsAddOrUpdate', '', false, orgId)"
                >新增商品</el-button
              >
              <el-button
                v-if="isAuth('tc:package:save')"
                type="primary"
                @click="$dialog('packageAddOrUpdate', '', false, orgId)"
                >新增套餐</el-button
              >
            </el-form-item>
          </div>
        </el-form>
        <el-table
          :data="goodsList"
          border
          stripe
          ref="goodsList"
          v-loading="goodsListLoading"
          @row-click="$clickRow($event, 'goodsList')"
          @selection-change="$selectionChange($event, 'goodsList')"
          style="width: 100%"
          @select-all="handleSelectAll"
        >
          <el-table-column
            prop="name"
            header-align="center"
            align="center"
            label="商品名称"
          >
          </el-table-column>

          <el-table-column
            prop="sn"
            header-align="center"
            align="center"
            label="商品编号"
          >
          </el-table-column>

          <el-table-column
            prop="imageUrl"
            header-align="center"
            align="center"
            label="商品图片"
          >
            <template slot-scope="scope">
              <img
                style="height: 50px; width: 50px"
                :src="scope.row.imageUrl"
                @click="openImg(scope.row.imageUrl)"
              />
            </template>
          </el-table-column>

          <el-table-column
            prop="salesVolume"
            header-align="center"
            align="center"
            label="销量"
          >
          </el-table-column>

          <el-table-column
            prop="isUse"
            header-align="center"
            align="center"
            label="是否使用"
          >
            <template slot-scope="scope">
              <el-switch
                v-model="scope.row.isUse"
                @change="changeUse(scope.row)"
              >
              </el-switch>
            </template>
          </el-table-column>

          <el-table-column
            prop="price"
            header-align="center"
            align="center"
            label="价格"
          >
          </el-table-column>

          <el-table-column
            prop="isPackage"
            header-align="center"
            align="center"
            label="是否套餐"
          >
            <template slot-scope="scope">
              <el-tag v-if="scope.row.isPackage" size="mini">是</el-tag>
              <el-tag v-else-if="!scope.row.subsidyType" type="info" size="mini"
                >否</el-tag
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="allowInSales"
            header-align="center"
            align="center"
            label="可现售"
          >
            <template slot-scope="scope">
              <el-switch
                v-model="scope.row.allowInSales"
                :disabled="scope.row.isPackage"
                @change="changeUse(scope.row)"
              >
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column
            prop="createdAt"
            header-align="center"
            align="center"
            label="创建时间"
          >
          </el-table-column>
          <el-table-column
            prop="createdBy"
            header-align="center"
            align="center"
            label="创建者"
          >
          </el-table-column>
          <el-table-column
            header-align="center"
            align="center"
            width="220"
            label="操作"
          >
            <template slot-scope="scope">
              <el-button
                v-if="isAuth('tc:goods:info') && !scope.row.isPackage"
                type="text"
                size="small"
                @click="$dialog('goodsAddOrUpdate', scope.row.id, true, orgId)"
                >查看商品</el-button
              >
              <el-button
                v-if="isAuth('tc:package:info') && scope.row.isPackage"
                type="text"
                size="small"
                @click="
                  $dialog('packageAddOrUpdate', scope.row.id, true, orgId)
                "
                >查看套餐</el-button
              >
              <el-button
                v-if="isAuth('tc:goods:update') && !scope.row.isPackage"
                type="text"
                size="small"
                @click="$dialog('goodsAddOrUpdate', scope.row.id, false, orgId)"
                >修改</el-button
              >
              <el-button
                v-if="isAuth('tc:package:update') && scope.row.isPackage"
                type="text"
                size="small"
                @click="
                  $dialog('packageAddOrUpdate', scope.row.id, false, orgId)
                "
                >修改套餐</el-button
              >
              <el-button
                v-if="isAuth('tc:goods:delete') && !scope.row.isPackage"
                type="text"
                size="small"
                @click="deleteGoodsHandle(scope.row.id)"
                >删除</el-button
              >
              <el-button
                v-if="isAuth('tc:package:delete') && scope.row.isPackage"
                type="text"
                size="small"
                @click="deletePackageHandle(scope.row.id)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <list-footer>
          <el-pagination
            background
            @size-change="$sizeChange($event, 'goodsList')"
            @current-change="$currentChange($event, 'goodsList')"
            :current-page="goodsListIndex"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="goodsListSize"
            :total="goodsListCount"
            layout="total, sizes, prev, pager, next, jumper"
          />
        </list-footer>
      </div>
    </div>

    <!-- 弹窗, 新增 / 修改 -->
    <category
      v-if="categoryVisible"
      ref="category"
      @success="getCategoryList"
    />

    <goods-add-or-update
      v-if="goodsAddOrUpdateVisible"
      ref="goodsAddOrUpdate"
      @success="getGoodsList(null, 1)"
    />

    <package-add-or-update
      v-if="packageAddOrUpdateVisible"
      ref="packageAddOrUpdate"
      @success="getGoodsList(null, 1)"
    />
  </div>
</template>

<script>
import { threeListMixin, listMixin, normal, deleteListMixin } from '@/mixins';
import GoodsAddOrUpdate from './goods-add-or-update';
import Category from './category-add-or-update';
import PackageAddOrUpdate from './package-add-or-update';
import { getOrgList } from '@/utils/options.js';
import ElTableDraggable from 'el-table-draggable';

export default {
  mixins: [threeListMixin, listMixin, normal, deleteListMixin],
  data() {
    return {
      dataForm: {
        categoryId: '',
        orgId: '',
      },
      isMove: false,
      categoryList: [],
      orgId: '',
      chooseIndex: 0,
      goodsList: [],
      goodsListSelections: [],
      goodsListLoading: false,
      goodsListSize: 10,
      goodsListIndex: 1,
      goodsListCount: 0,
      orgList: [],
      goodsAddOrUpdateVisible: false,
      packageAddOrUpdateVisible: false,
      categoryVisible: false,
      isPackageList: [
        { id: 1, isPackage: '是' },
        { id: 0, isPackage: '否' },
      ],
      isUseList: [
        { id: 1, isUse: '是' },
        { id: 0, isUse: '否' },
      ],
    };
  },
  components: {
    Category,
    GoodsAddOrUpdate,
    PackageAddOrUpdate,
    ElTableDraggable,
  },
  activated() {},
  created() {
    this.getOrgList();
  },
  methods: {
    tableRowClassName({ row }) {
      if (row.id === this.chooseIndex) {
        return 'danger-row';
      } else {
        return '';
      }
    },
    colorChange(row) {
      this.chooseIndex = row.id;
    },
    renderHeader(h, { column }) {
      return h('div', [
        h('span', column.label),
        h(
          'el-tooltip',
          {
            undefined,
            props: {
              undefined,
              effect: 'dark',
              content: '是否在小程序的商品列表侧边栏上显示',
              placement: 'top',
            },
          },
          [
            h('i', {
              undefined,
              class: 'el-icon-question',
              style: 'color:#409eff;margin-left:5px;cursor:pointer;',
            }),
          ],
          { content: '提示消息' },
        ),
      ]);
    },
    getCategoryList() {
      this.$http({
        url: `/cc/category/page`,
        params: {
          orgId: this.orgId,
          perPage: -1,
          orderBy: 'orderNum',
          orderDir: 'asc',
        },
      }).then(({ data }) => {
        if (data && data.status === 0) {
          this.categoryList = data.data.items;
          this.dataForm.orgId = this.orgId;
          this.getGoodsList(null, 1);
        }
      });
    },
    changeCategoryShow(id, isShow) {
      this.$http({
        url: `/cc/category/ids/update/boolean`,
        method: 'post',
        data: [
          {
            id: id,
            isShow: isShow,
          },
        ],
        after: () => {
          this.getCategoryList();
        },
      });
    },
    deleteCategory(id) {
      let ids = id;

      this.$confirm('确定删除操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$http({
          url: '/cc/category/ids/delete',
          data: { ids },
          method: 'post',
        }).then(({ data }) => {
          if (data && data.status === 0) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1500,
            });
            this.getCategoryList();
          }
        });
      });
    },
    getOrgList() {
      getOrgList(0, 1).then(({ data }) => {
        if (data && data.status === 0) {
          this.orgList = data.data.items;
          if (this.orgId === '') {
            this.orgId = this.orgList[0].id;
            this.getCategoryList();
          }
        }
      });
    },
    getGoodsList(row, page) {
      if (row) {
        this.dataForm.categoryId = row.id;
      }
      if (page) {
        this.goodsListIndex = page;
      }

      this.$api({
        url: `/tc/goods/goodsAndPackage`,
        params: {
          page: this.goodsListIndex,
          limit: this.goodsListSize,
          ...this.dataForm,
        },
        after: (data) => {
          if (data && data.code === 0) {
            this.goodsList = data.page.records;
            this.goodsListCount = data.page.total;
          } else {
            this.goodsList = [];
            this.goodsListCount = 0;
          }
        },
      });
    },
    changeUse(data) {
      this.$http({
        url: `/tc/goods/boolean`,
        method: 'post',
        data: {
          id: data.id,
          isPackage: data.isPackage,
          isUse: data.isUse,
          allowInSales: data.allowInSales,
        },
        after: () => {
          this.getGoodsList(null, 1);
        },
      });
    },
    // 删除
    deleteGoodsHandle(id) {
      let ids = id
        ? [id]
        : this.goodsListSelections.map((item) => {
            return item.id;
          });
      this.$handlePureDelete({
        tip: `是否确认对这些商品进行删除操作?`,
        url: '/tc/goods/delete',
        data: ids,
        after: () => {
          this.getGoodsList(null, 1);
        },
      });
    },
    // 删除
    deletePackageHandle(id) {
      let ids = id
        ? [id]
        : this.goodsListSelections.map((item) => {
            return item.id;
          });
      this.$handlePureDelete({
        tip: `是否确认对这些套餐进行删除操作?`,
        url: '/tc/package/delete',
        data: ids,
        after: () => {
          this.getGoodsList(null, 1);
        },
      });
    },
    changeNum() {
      let ids = this.categoryList
        .map((item) => {
          return item.id;
        })
        .join(',');
      this.$http({
        url: `/cc/category/ids/update-order`,
        method: 'post',
        data: { ids },
      }).then(({ data }) => {
        if (data && data.status === 0) {
          this.cancelMove();
          this.$message({
            message: '提交成功',
            type: 'success',
            duration: 1500,
          });
        }
      });
    },
    cancelMove() {
      this.isMove = false;
      this.getCategoryList();
    },
    changeDragg() {
      this.isMove = true;
    },
  },
};
</script>
